export class Analytics {
  analyticsObject?: ServiceTransactionAnalytics;     // field name subject to change
  billingAccountNums?: string;
  channel: string = "CAM";
  contentType?: string;
  contentID?: string;
  dscApplicationName?: string;
  dscTransactionType?: string;
  isGold?: boolean;
  isMultiMAP?: boolean;
  isMultiRenewal?: boolean;
  mmaPartyID?: string;
  policyCount?: number;
  policyEsignCount?: number;
  policyLineOfBusiness?: string;
  policyNumbers?: string;
  policyNumbersEsignWarning?: string;
  policyRatingStates?: string;
  policyRenewalDate?: string;
  pageName?: string;
  pageSubName?: string;
  toolName?: string;
  userID?: string;
}

export class ABTestAnalytics {
  experimentName: string; 
  variantName: string;
  policies: string;
  siteSection?: string;
  TileName?: string;
}

export class ServiceAnalytics {
  toolName: string;               // name of feature/flow
  contentID: string;              // name of feature/flow
  contentType: string;
  "confirm.service": string;      // flow start or end
  mmaPartyID: string;
  polNum: string;                 // single policy number OR list of policies on a single billing account being transacted on
}

export class ViewAnalytics {
  contentType: string;
  contentID: string;              // name of feature/flow
  mmaPartyID: string;
  polNum: string;                 // single policy number OR list of policies on a single billing account being transacted on
}

export class EsignServiceAnalytics {
  applicationID: string = "eSign";          // name of feature/flow 
  siteSection: string = "Policy";
  TileName: string;              // start or confirmation
  "confirm.eSign": string;        // eSign flow start or end
  eSignSigners: string;           // single or multi signer
  eSignPolicies: string;          // single or multi transaction
  policies: string;               // policies to be signed or being signed
}

export class PaperlessOptionsAnalytics {
  TileName: string;
  policies: string;
  applicationID?: string;          // name of feature/flow 
  siteSection?: string;
  "confirm.cpc"?: string;
  paperlessDocType?: string;
}

export class PaperlessModalAnalytics {
  TileName: string;
  policies: string;
  contentType?: string;          // name of feature/flow 
  contentID?: string;
  "confirm.cpc"?: string;
  paperlessDocType?: string;
}

export class ServiceTransactionAnalytics {
  dscEventType: string;             // "Start" or "Complete"
  dscTransactionType: string;       //e.g. value AnalyticsTransactions.RPP
  dscApplicationName: string;       //e.g. value AnalyticsApplicationNames.ENROLL_AUTOPAY
  dscApplicationDetail: string;
  polNum: string;                   // single policy number OR list of policies on a single billing account being transacted on
  jurisdiction: string;             // state/jurisdiciton
  contentType: string;              // Added for Change Email complete
  contentID: string;                // Added for Change Email complete
}

export class ServiceTransactionUtagAnalytics {
  analyticsObject: ServiceTransactionAnalytics;
  pageName: string;                 // same as camDataLayer; first path param after domain
  pageSubName: string;              // same as camDataLayer; second path param after domain
  contentType: string;              // ContentType.MODAL ("modal") or ContentType.ACTION ("action") 
  contentID: string;                // format: {dscApplicationName}-{dscEventType}
}

export class ErrorAnalytics {
  contentType: string;
  contentID: string;              // short error name or number
}