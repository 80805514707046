import { inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

export default class OverrideExperience {
  private route: ActivatedRoute;

  /* Info Card: displayed as primary card on homepage (include infoCardLob if infoCard is crossSell) */
  infoCard: 'autopaySignup' | 'billingSms' | 'crossSell' | 'customerAppreciation' | 'mobileAppPromotion' | 'multiPolicyDiscount' | 'newBusiness' | 'paperlessBilling' | 'renewal';
  infoCardLob: 'auto' | 'home' | 'renters' | 'umbrella' | 'earthquake' | 'motorcycle' | 'watercraft';

  /* Notifications: displayed on homepage / documents page */
  notification: 'esign' | 'agentEsign' | 'discounts' | 'singleCA';

  /* Agent type: GPL and GLD both indicate that an agency isGold */
  agentType: 'GPL' | 'GLD' | 'AGT' | 'GXH' | 'CSU'

  /* Experience overrides */
  experience: string;
  experienceGroup: 'C1' | 'T1' | 'T2';

  /* Feature flag overrides */
  feature: string;

  constructor(private activatedRoute?: ActivatedRoute) {
    if (environment.allowOverrides) {
      this.route = activatedRoute || inject(ActivatedRoute);

      this.infoCard = this.route.snapshot.queryParamMap.get('info-card') as any;
      this.infoCardLob = this.route.snapshot.queryParamMap.get('info-card-lob') as any;
      this.notification = this.route.snapshot.queryParamMap.get('notification') as any;
      this.agentType = this.route.snapshot.queryParamMap.get('agent-type') as any;
      this.experience = this.route.snapshot.queryParamMap.get('experience') as any;
      this.experienceGroup = this.route.snapshot.queryParamMap.get('experience-group') as any;
      this.feature = this.route.snapshot.queryParamMap.get('feature') as any;
    }
  }
}
