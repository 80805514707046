import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject, forkJoin } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { Location, DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import Endpoints from 'src/app/utilities/constants/endpoints';
import Browsers from 'src/app/utilities/constants/browsers';

import { Alert, AlertType } from 'src/app/utilities/models/alert';
import { Features } from 'src/app/utilities/models/features';
import { FieldValidation } from '../../models/fieldValidation';
import Footer from '../../models/footer';
import { WebAnalytics } from '../../models/webAnalytics';
import { ComponentDisplay as cd } from 'src/app/utilities/constants/componentDisplay';
import { Title } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import OverrideExperience from '../../models/overrideExperience';

declare let camDataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  featureObs: Observable<Features>;
  featuresV2Obs: Observable<FeaturesResponse>;
  experiencesV2Obs: Observable<FeaturesResponse>;
  features: Features = null;

  footerObs: Observable<FooterResponse>;
  footer: Footer = null;

  document: Document;
  private queue: Alert[] = new Array<Alert>();
  private bsQueue: BehaviorSubject<Alert[]> = new BehaviorSubject<Alert[]>(this.queue);
  private serviceFailure = false;
  deviceInfo = null;
  webAnalytics: WebAnalytics[] = [];

  constructor(
    private http: HttpClient,
    private location: Location,
    private route: ActivatedRoute,
    private titleService: Title,
    private deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) document
  ) {
    this.document = document;
    this.featureObs = this.http.get<Features>(Endpoints.api.getFeatures, { withCredentials: true }).pipe(share());
    this.featuresV2Obs = this.http.get<FeaturesResponse>(Endpoints.api.getFeaturesV2, { withCredentials: true }).pipe(share());
    this.experiencesV2Obs = this.http.get<FeaturesResponse>(Endpoints.api.getExperiencesV2, { withCredentials: true }).pipe(share());
    this.footerObs = this.http.get<FooterResponse>(Endpoints.api.getCamFooter, { withCredentials: true }).pipe(share());
  }

  getFeatures(): Promise<Features> {
    return new Promise((resolve, reject) => {
      
      let emptyFeatures: Features;

      const features$ = this.featureObs.pipe(
        catchError(error => {
          return of({}); // Return null in case of error
        })
      );
      const featuresv2$ = this.featuresV2Obs.pipe(
        catchError(error => {
          return of({ result: emptyFeatures }); // Return null in case of error
        })
      );
      const experiences$ = this.experiencesV2Obs.pipe(
        catchError(error => {
          return of({ result: emptyFeatures }); // Return null in case of error
        })
      );
  
      forkJoin([features$, featuresv2$, experiences$])
        .pipe(
          map(([featuresRs, featuresv2Rs, experiencesRs]) => {
            let combinedFeatures: Features = {
              ...featuresRs,
              ...featuresv2Rs.result,
              ...experiencesRs.result
            };
            return combinedFeatures;
          })
        )
        .subscribe({
          next: (combinedResponse) => {
            this.features = combinedResponse;
            const override = new OverrideExperience(this.route);
            if (override.experience) {  
              this.features = {...this.features, experiences: [{ name: override.experience, group: override.experienceGroup || 'T1' }]};
            }
            if (override.feature) {
              this.features = {...this.features, [override.feature]: true}
            }
            resolve(this.features);
          },
          error: (error) => {
            this.setServiceFailureAlert('MMA-View-NotificationSystemError|GetFeatures');
            reject({ error: error });
          }
        });
    });
  }

  getCamFooter(): Promise<Footer> {
    return new Promise((resolve, reject) => {
      this.footerObs.subscribe({
        next: (response) => {
          this.footer = response.result;
          resolve(this.footer);
        },
        error: (error) => {
          this.setServiceFailureAlert('MMA-View-NotificationSystemError|GetCamFooter');
          reject({ error: error });
        }
      });
    });
  }


  setADATitleFocus() {
    setTimeout(() => {
      const pageTitle: HTMLElement = this.document.getElementsByTagName('h1')[0];
      if (pageTitle) {
        pageTitle.setAttribute('tabindex', '-1');
        pageTitle.setAttribute('style', 'outline: none;');
        pageTitle.focus();
      }
    });
  }

  getAlertQueue() {
    return this.bsQueue;
  }

  createAlert(type: AlertType, alertMessages: string[], heapID: string) {
    const newAlert: Alert = new Alert(type);
    for (const message of alertMessages) {
      newAlert.messages.push(message);
    }
    newAlert.heapId = heapID;
    return newAlert;
  }

  pushAlert(alert: Alert, initialLocation?: string): Observable<Alert[]> {
    if (this.displayNotifications(alert.heapId, initialLocation)) {
      this.queue.push(alert);
      this.bsQueue.next(this.queue);
    }

    if (alert.heapId && (<any>window) && (<any>window).heap && (<any>window).heap.track) {
      (<any>window).heap.track(alert.heapId);
    }

    return of(this.queue);
  }

  popAlerts() {
    this.serviceFailure = false;
    while (this.queue.length) {
      this.queue.pop();
      this.bsQueue.next(this.queue);
    }
  }

  setServiceFailureAlert(heapId?: string, initialLocation?: string) {
    if (!this.serviceFailure) {
      this.serviceFailure = true;
      const serviceFailureAlert: Alert = new Alert(AlertType.NEGATIVE);
      serviceFailureAlert.messages.push('Oh dear, something went wrong. Please check back soon.');
      serviceFailureAlert.heapId = heapId;
      this.pushAlert(serviceFailureAlert, initialLocation);
    }

    window.scroll(0, 0);
  }
  
  setMissingInfoServiceFailureAlert(heapId?: string, initialLocation?: string) {
    if (!this.serviceFailure) {
      const serviceFailureAlert: Alert = new Alert(AlertType.NEGATIVE);
      serviceFailureAlert.messages.push(`Sorry, we can't load all of your information right now. Please check back later.`);
      serviceFailureAlert.heapId = heapId;

      this.pushAlert(serviceFailureAlert, initialLocation);
      this.serviceFailure = !!(this.queue && this.queue.length);
    }

    window.scroll(0, 0);
  }

  private displayNotifications(heapId: string, initialLocation: string) {
    const location = this.location.path();
    let showNotification = false;

    if(this.queue.find(alert => alert.heapId === heapId)) {
      return false;
    }
    
    if (heapId && heapId.includes('MMA-View-Notification') && heapId.includes('|')) {
      const split = heapId.split('|');
      heapId = (split && split.length > 1) ? split[1] : '';
    }

    if (heapId) {
      cd.display.notifications.some((page) => {
        if (~location.indexOf(page.url) && page.heapIds.some((checkHeapId: string) => checkHeapId === heapId)) {
          return showNotification = true;
        }
      });
    }

    if (initialLocation && location !== initialLocation) {
      showNotification = false;
    }

    return showNotification;
  }

  supress(componentsToHide: string[], url: string) {
    let pageMatch = false;

    componentsToHide.some((page: string) => {
      if ((~url.indexOf(page) && page.length) || page === url) {
        return pageMatch = true;
      }
    });
    return pageMatch;
  }

  getUserAgent() {
    const uaReg = new RegExp(/(MSIE|Trident|(?!Gecko.+)Firefox|(?!AppleWebKit.+Chrome.+)Safari(?!.+Edge)|(?!AppleWebKit.+)Chrome(?!.+Edge)|(?!AppleWebKit.+Chrome.+Safari.+)Edge|AppleWebKit(?!.+Chrome|.+Safari)|Gecko(?!.+Firefox))(?: |\/)([\d.apre]+)/g);
    const userAgentArry: string[] = uaReg.exec(navigator.userAgent);

    if (userAgentArry && userAgentArry[1] && userAgentArry[1].length) {
      return userAgentArry[1].toUpperCase();
    } else {
      return Browsers.NOT_FOUND;
    }
  }

  isMobileApp() {
    const userAgent = navigator.userAgent.toLowerCase();
    return (userAgent.indexOf('safecoconsumer') > -1);
  }

  isMobileDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    // const isTablet = this.deviceService.isTablet();
    // const isDesktopDevice = this.deviceService.isDesktop();

    return isMobile;
  }

  isTabletDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isTablet = this.deviceService.isTablet();

    return isTablet;
  }

  isDesktopDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isDesktop = this.deviceService.isDesktop();
    return isDesktop;
  }

  scrollTo(inputElementId?: string) {
    let elementId: string;

    if (!inputElementId) {
      // If there is a scrollTo query parameter, scroll to that Id
      const scrollToElement = this.route.snapshot.queryParamMap.get('section');
      elementId = (scrollToElement) ? scrollToElement : 'app-root';
    } else {
      elementId = inputElementId;
    }

    setTimeout(() => {
      const elementById: HTMLElement = document.getElementById(elementId);
      if (elementById) {
        elementById.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  hasValidationErrors(componentAttributes: Object, showErrors?: boolean) {
    const fieldValidation: FieldValidation[] = [];
    let hasError = false;

    Object.entries(componentAttributes).forEach(([key, value]) => {
      if (~key.indexOf('Validation')) {
        fieldValidation.push(value);
      }
    });

    fieldValidation.forEach(validation => {
      if (validation.hasError) {
        if (showErrors) {
          validation.forceValidation = true;
        }
        hasError = true;
      }
    });

    return hasError;
  }

  setHtmlTitle(title: string) {
    this.titleService.setTitle(title);
  }

  redirectWithParams(loc: string, url: string) {
    let param = '';
    if (loc.indexOf('?') > -1) {
      const params = loc.split('?');
      param = '?' + params[1];
    }
    window.location.href = url + param;
  }

  getParams(loc: string) {
    let param = '';
    if (loc.indexOf('?') > -1) {
      const params = loc.split('?');
      param = '&' + params[1];
    }
    return param;
  }

  areCookiesEnabled() {
    try {
      /* commenting out because this doesn't work for IE; leave as a reference when we no longer support IE?
       if (navigator.cookieEnabled) {
        return true;
      } */
      document.cookie = 'cookietest=1';
      const cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
      return cookiesEnabled;
    } catch (e) {
      return false;
    }
  }

  public loadScript(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const node = document.createElement('script');
      node.onload = () => {
        resolve(url);
      };
      node.onerror = err => {
        reject(err);
      };
      node.src = url;
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }
}

class FooterResponse {
  result: Footer;
  status: {
    code: number;
  };
}

class FeaturesResponse {
  result: Features;
  status: {
    code: number;
  };
}