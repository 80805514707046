import { trigger, state, style, transition, animate, group, keyframes } from '@angular/animations';

// Primary animation for esign-alert component
// First triggers vertical expansion -> triggers component to slide into the space from lefthand side
export const SlideInOutAnimation = trigger('slideInOut', [
  state('in', style({
    'max-height': '*px', 'opacity': '1', 'visibility': 'visible'
  })),
  state('out', style({
    'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
  })),
  transition('out => in', [group([
    animate('3400ms ease-in-out', style({
      'visibility': 'visible'
    })),
    animate('1000ms ease-in-out', style({
      'max-height': '500px'
    })),
    animate('900ms ease-in-out', keyframes([
      style({ opacity: 0, transform: 'translateX(-100%)', offset: 0 }),
      style({ opacity: 0.5, transform: 'translateX(-30%)', offset: 0.7 }),
      style({ opacity: 1, transform: 'translateX(0)', offset: 1 }),
    ]))
  ])])
]);

// Alternate animation for esign-alert component utilizing a fade-in vs. slide-in
export const expandFadeAnimation = [
  trigger('slideInOut', [
    state('in', style({
      'max-height': '500px', 'opacity': '1', 'visibility': 'visible'
    })),
    state('out', style({
      'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
    })),
    transition('in => out', [group([
        animate('400ms ease-in-out', style({
          'opacity': '0'
        })),
        animate('600ms ease-in-out', style({
          'max-height': '0px'
        })),
        animate('700ms ease-in-out', style({
          'visibility': 'hidden'
        }))
      ]
    )]),
    transition('out => in', [group([
        animate('300ms ease-in-out', style({
          'visibility': 'visible'
        })),
        animate('1800ms ease-in-out', style({
          'max-height': '500px'
        })),
        animate('1200ms ease-in-out', style({
          'opacity': '1'
        }))
      ]
    )])
  ]),
];
