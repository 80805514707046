import { HttpClient, HttpHeaders, HttpStatusCode} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { Observable } from 'rxjs';

import Endpoints from 'src/app/utilities/constants/endpoints';

import { PaperlessDocuments, PaperlessDocumentsStatus, PaperlessDocumentStatusCodes } from 'src/app/utilities/models/paperlessDocuments';
import { PaperlessPolicy } from 'src/app/utilities/models/paperlessPolicy';
import Agency from 'src/app/utilities/models/agency';
import Billing from 'src/app/utilities/models/billing';
import PaperlessBilling from 'src/app/utilities/models/paperlessBilling';
import Policy from 'src/app/utilities/models/policy';
import PaperlessTermsAndConditions from '../../models/paperlessTermsAndConditions';

import { BillingService } from 'src/app/utilities/services/billing-service/billing.service';
import { PolicyService } from 'src/app/utilities/services/policy-service/policy.service';
import { RequestCache } from 'src/app/utilities/interceptors/request-cache.service';

import BillingMethods from 'src/app/utilities/methods/billing.methods';
import PolicyMethods from 'src/app/utilities/methods/policy.methods';
import { AnalyticsService } from '../analytics-service/analytics.service';
import { AuthUser } from '../../models/authUser';
import { AccountService } from '../account-service/account.service';
//import { features } from 'process';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { Features } from 'src/app/utilities/models/features';


@Injectable({
  providedIn: 'root'
})
export class PaperlessService {
  paperlessBillingObs: Observable<GetPaperlessBillingResponse> = null;
  paperlessPolicyObs: Observable<GetPaperlessPolicyResponse> = null;
  paperlessUpdateAllEmailsObs: Observable<PaperlessDocumentsResponse> = null;
  updateAllPaperlessObs: Observable<PaperlessDocumentsResponse> = null;
  updatePaperlessBillingAccountsObs: Observable<PaperlessDocumentsResponse> = null;
  updatePaperlessPoliciesObs: Observable<PaperlessDocumentsResponse> = null;
  updateReenrollPaperlessPoliciesObs: Observable<PaperlessDocumentsResponse> = null;
  paperlessTermsAndConditionsObs: Observable<PaperlessTermsResponse> = null;
  features: Features = new Features();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    }),
    withCredentials: true
  };

  httpOptionsSimple = {
    withCredentials: true
  };

  constructor(
    private billingService: BillingService,
    private accountService: AccountService,
    private http: HttpClient,
    private policyService: PolicyService,
    private requestCache: RequestCache,
    private analyticsService: AnalyticsService,
    private commonService: CommonService
  ) { }

  updateAllPaperless(paperlessDocumentsRq: PaperlessDocuments): Promise<PaperlessDocuments> {
    return new Promise((resolve, reject) => {
      this.updateAllPaperlessObs = this.http.post<PaperlessDocumentsResponse>(Endpoints.api.updateAllPaperless, paperlessDocumentsRq, this.httpOptionsSimple);

      this.updateAllPaperlessObs.subscribe({
        next: (response: PaperlessDocumentsResponse) => {
          if (response && response.result) {
            const paperlessDocuments: PaperlessDocuments = response.result;

            this.requestCache.cacheBust(Endpoints.api.updateAllPaperless);

            resolve(paperlessDocuments);
          } else {
            reject();
          }
        },
        error: (error) => reject({ error: error })
      });
    });
  }

  updatePaperlessBillingAccounts(paperlessDocumentsRq: PaperlessDocuments): Promise<PaperlessDocuments> {
    return new Promise((resolve, reject) => {
      this.updatePaperlessBillingAccountsObs = this.http.post<PaperlessDocumentsResponse>(Endpoints.api.updatePaperlessBillingAccounts, paperlessDocumentsRq, this.httpOptionsSimple);

      this.updatePaperlessBillingAccountsObs.subscribe({
        next: (response: PaperlessDocumentsResponse) => {
          if (response && response.result) {
            const paperlessDocuments: PaperlessDocuments = response.result;

            this.requestCache.cacheBust(Endpoints.api.updatePaperlessBillingAccounts);

            resolve(paperlessDocuments);
          } else {
            reject();
          }
        },
        error: (error) => reject({ error: error })
      });
    });
  }

  updatePaperlessPolicies(paperlessDocumentsRq: PaperlessDocuments): Promise<PaperlessDocuments> {
    return new Promise((resolve, reject) => {
      const isPaperlessUpdatePoliciesEnabled: boolean = this.commonService.features?.paperless_update_policies_enabled;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        }),
        withCredentials: true
      };
      const endpoint:string = isPaperlessUpdatePoliciesEnabled ? Endpoints.cloudApi.updatePaperlessPolicies : Endpoints.api.updatePaperlessPolicies;
      const httpOpt = isPaperlessUpdatePoliciesEnabled ? httpOptions : this.httpOptionsSimple;
      this.updatePaperlessPoliciesObs = this.http.post<PaperlessDocumentsResponse>(endpoint, paperlessDocumentsRq, httpOpt);

      this.updatePaperlessPoliciesObs.subscribe({
        next: (response: PaperlessDocumentsResponse) => {
          if (response && response.result) {
            const paperlessDocuments: PaperlessDocuments = response.result;

            this.requestCache.cacheBust(endpoint);

            resolve(paperlessDocuments);
          } else {
            reject();
          }
        },
        error: (error) => reject({ error: error })
      });
    });
  }

  // For CA Paperless Re-enrollment
  // call service to store Paperless T&C document for non compliant policies
  updateReenrollPaperlessPolicies(paperlessDocumentsRq: PaperlessDocuments): Promise<PaperlessDocuments> {
    return new Promise((resolve, reject) => {
      this.updateReenrollPaperlessPoliciesObs = this.http.post<PaperlessDocumentsResponse>(Endpoints.api.updateReenrollPaperlessPolicies, paperlessDocumentsRq, this.httpOptionsSimple);

      this.updateReenrollPaperlessPoliciesObs.subscribe({
        next: (response: PaperlessDocumentsResponse) => {
          if (response && response.result) {
            const paperlessDocuments: PaperlessDocuments = response.result;

            this.requestCache.cacheBust(Endpoints.api.updateReenrollPaperlessPolicies);

            resolve(paperlessDocuments);
          } else {
            reject();
          }
        },
        error: (error) => reject({ error: error })
      });
    });
  }

  updateAllPaperlessEmails(paperlessDocumentsRq: PaperlessDocuments): Promise<PaperlessDocuments> {
    return new Promise((resolve, reject) => {
      this.paperlessUpdateAllEmailsObs = this.http.post<PaperlessDocumentsResponse>(Endpoints.api.updateAllPaperlessEmails, paperlessDocumentsRq, this.httpOptionsSimple);

      this.paperlessUpdateAllEmailsObs.subscribe({
        next: (response: PaperlessDocumentsResponse) => {
          if (response && response.result && response.status.code === 0) {
            const paperlessDocuments: PaperlessDocuments = response.result;

            this.requestCache.cacheBust(Endpoints.api.updateAllPaperlessEmails);

            resolve(paperlessDocuments);
          } else {
            reject();
          }
        },
        error: (error) => reject({ error: error })
      });
    });
  }

  async updatePolicyAndBillingPaperlessEmails(): Promise<PaperlessDocuments> {
    let updateEmailResponse: PaperlessDocuments;

    try {
      const paperlessDocumentsRq: PaperlessDocuments = await this.buildPaperlessEmailRequest();

      if (paperlessDocumentsRq && ((paperlessDocumentsRq.paperlessBilling && paperlessDocumentsRq.paperlessBilling.length) || (paperlessDocumentsRq.paperlessPolicies && paperlessDocumentsRq.paperlessPolicies.length))) {
        updateEmailResponse = await this.updateAllPaperlessEmails(paperlessDocumentsRq);
      } else { // account has no paperless policies and billing to update email
        updateEmailResponse = new PaperlessDocuments();
        updateEmailResponse.status = new PaperlessDocumentsStatus();
        updateEmailResponse.status.statusCode = HttpStatusCode.NoContent;
      }
    } catch (ex) {
      updateEmailResponse = null;
    } finally {
      if (updateEmailResponse) {
        await this.clearCachesAfterUpdate(updateEmailResponse);

        if (updateEmailResponse.status) {
          this.setHeapIdForPaperlessUpdates(updateEmailResponse);
        }
      }
    }

    return updateEmailResponse;
  }

  async updateAllPaperlessBilling(newPaperlessStatus: boolean): Promise<PaperlessDocuments> {
    const paperlessDocumentsRq: PaperlessDocuments = new PaperlessDocuments();
    let paperlessDocumentsRs: PaperlessDocuments;

    try {
      const authUser: AuthUser = this.accountService.getAuthUser();

      if (authUser) {
        paperlessDocumentsRq.emailAddress = authUser.email;
        paperlessDocumentsRq.firstName = authUser.firstName;
        paperlessDocumentsRq.lastName = authUser.lastName;
        paperlessDocumentsRq.tandCsDateTime = this.setTandCsSignedDateTime(newPaperlessStatus);
        paperlessDocumentsRq.paperlessBilling = await this.buildBillingUpdateRequest(newPaperlessStatus);

        paperlessDocumentsRs = await this.updatePaperlessBillingAccounts(paperlessDocumentsRq);
      }

      if (!authUser || !paperlessDocumentsRs) {
        throw new Error();
      }
    } catch (err) {
      this.handlePaperlessUpdateException(err);
    } finally {
      if (paperlessDocumentsRs && paperlessDocumentsRs.status) {
        this.setHeapIdForPaperlessUpdates(paperlessDocumentsRs);
        await this.clearCaches(paperlessDocumentsRs);
      }
    }

    return paperlessDocumentsRs;
  }

  async updateAllPaperlessPolicies(newPaperlessStatus: boolean): Promise<PaperlessDocuments> {
    const paperlessDocumentsRq: PaperlessDocuments = new PaperlessDocuments();
    let paperlessDocumentsRs: PaperlessDocuments;

    try {
      const authUser: AuthUser = this.accountService.getAuthUser();

      if (authUser) {
        paperlessDocumentsRq.emailAddress = authUser.email;
        paperlessDocumentsRq.firstName = authUser.firstName;
        paperlessDocumentsRq.lastName = authUser.lastName;
        paperlessDocumentsRq.tandCsDateTime = this.setTandCsSignedDateTime(newPaperlessStatus);
        paperlessDocumentsRq.paperlessPolicies = await this.buildPoliciesUpdateRequest(newPaperlessStatus, authUser.firstName);

        paperlessDocumentsRs = await this.updatePaperlessPolicies(paperlessDocumentsRq);
      }

      if (!authUser || !paperlessDocumentsRs) {
        throw new Error();
      }
    } catch (err) {
      this.handlePaperlessUpdateException(err);
    } finally {
      if (paperlessDocumentsRs && paperlessDocumentsRs.status) {
        this.setHeapIdForPaperlessUpdates(paperlessDocumentsRs);
        await this.clearCaches(paperlessDocumentsRs);
      }
    }

    return paperlessDocumentsRs;
  }

  async updateAllPaperlessPoliciesAndBilling(newPaperlessStatus: boolean): Promise<PaperlessDocuments> {
    const paperlessDocumentsRq: PaperlessDocuments = new PaperlessDocuments();
    let paperlessDocumentsRs: PaperlessDocuments;

    try {
      const authUser: AuthUser = this.accountService.getAuthUser();

      if (authUser) {
        paperlessDocumentsRq.emailAddress = authUser.email;
        paperlessDocumentsRq.firstName = authUser.firstName;
        paperlessDocumentsRq.lastName = authUser.lastName;

        if (newPaperlessStatus) {
          paperlessDocumentsRq.tandCsDateTime = this.setTandCsSignedDateTime(newPaperlessStatus);
        }

        paperlessDocumentsRq.paperlessBilling = await this.buildBillingUpdateRequest(newPaperlessStatus);
        paperlessDocumentsRq.paperlessPolicies = await this.buildPoliciesUpdateRequest(newPaperlessStatus, authUser.firstName);

        paperlessDocumentsRs = await this.updateAllPaperless(paperlessDocumentsRq);
      }

      if (!authUser || !paperlessDocumentsRs) {
        throw new Error();
      }
    } catch (err) {
      this.handlePaperlessUpdateException(err);
    } finally {
      if (paperlessDocumentsRs && paperlessDocumentsRs.status) {
        this.setHeapIdForPaperlessUpdates(paperlessDocumentsRs);
        await this.clearCaches(paperlessDocumentsRs);
      }
    }

    return paperlessDocumentsRs;
  }

  // NB Esign Paperless
  // given a list of policy numbers and list of billing accounts update paperless status to new paperless status
  // Added for NB Paperless
  async updatePaperlessSpecificPoliciesAndBilling(newPaperlessStatus: boolean, policyNumbers: string[], billingAccounts: Billing[]): Promise<PaperlessDocuments> {
    const paperlessDocumentsRq: PaperlessDocuments = new PaperlessDocuments();
    let paperlessDocumentsRs: PaperlessDocuments;

    try {
      const authUser: AuthUser = this.accountService.getAuthUser();

      if (authUser) {
        paperlessDocumentsRq.emailAddress = authUser.email;
        paperlessDocumentsRq.paperlessBilling = await this.buildBillingUpdateRequestForBillingAccounts(billingAccounts, newPaperlessStatus);
        paperlessDocumentsRq.paperlessPolicies = await this.buildPoliciesUpdateRequestForPoliciesList(policyNumbers, newPaperlessStatus, authUser.firstName);

        paperlessDocumentsRs = await this.updateAllPaperless(paperlessDocumentsRq);
      }

      if (!authUser || !paperlessDocumentsRs) {
        throw new Error();
      }
    } catch (err) {
      this.handlePaperlessUpdateException(err);
    } finally {
      if (paperlessDocumentsRs && paperlessDocumentsRs.status) {
        this.setHeapIdForPaperlessUpdates(paperlessDocumentsRs);
        await this.clearCaches(paperlessDocumentsRs);
      }
    }

    return paperlessDocumentsRs;
  }

  // For CA Re-enrollment - call service to store Paperless T&C document
  async updateReenrollPaperlessSpecificPolicies(policyNumbers: string[]): Promise<PaperlessDocuments> {
    const paperlessDocumentsRq: PaperlessDocuments = new PaperlessDocuments();
    let paperlessDocumentsRs: PaperlessDocuments;

    try {
      const authUser: AuthUser = this.accountService.getAuthUser();

      if (authUser) {
        paperlessDocumentsRq.emailAddress = authUser.email;
        paperlessDocumentsRq.firstName = authUser.firstName;
        paperlessDocumentsRq.lastName = authUser.lastName;
        paperlessDocumentsRq.tandCsDateTime = this.setTandCsSignedDateTime(true);
        paperlessDocumentsRq.paperlessPolicies = await this.buildPoliciesUpdateRequestForReenrollPoliciesList(policyNumbers, authUser.firstName);

        paperlessDocumentsRs = await this.updateReenrollPaperlessPolicies(paperlessDocumentsRq);
      }

      if (!authUser || !paperlessDocumentsRs) {
        throw new Error();
      }
    } catch (err) {
      this.handlePaperlessUpdateException(err);
    } finally {
      if (paperlessDocumentsRs && paperlessDocumentsRs.status) {
        this.setHeapIdForPaperlessUpdates(paperlessDocumentsRs);
        await this.clearCaches(paperlessDocumentsRs);
      }
    }

    return paperlessDocumentsRs;
  }


  async clearCaches(paperlessDocumentsRs: PaperlessDocuments): Promise<void> {
    if (paperlessDocumentsRs.paperlessBilling && paperlessDocumentsRs.paperlessBilling.length) {
      this.requestCache.cacheBust(Endpoints.api.getBilling);
    }

    if (paperlessDocumentsRs.paperlessPolicies && paperlessDocumentsRs.paperlessPolicies.length) {
      await this.policyService.clearPolicyCache();
    }
  }

  handlePaperlessUpdateException(err: Error): PaperlessDocuments {
    const paperlessDocuments = new PaperlessDocuments();

    paperlessDocuments.status = new PaperlessDocumentsStatus();
    paperlessDocuments.status.statusCode = PaperlessDocumentStatusCodes.exception;
    paperlessDocuments.status.statusDescription = err.toString();

    return paperlessDocuments;
  }

  async buildBillingUpdateRequest(newPaperlessStatus: boolean): Promise<PaperlessBilling[]> {
    let billingAccounts: Billing[] = [];
    const paperlessBillingAccounts: PaperlessBilling[] = [];

    try {
      billingAccounts = await this.billingService.getBilling();
    } catch (ex) {
      billingAccounts = null;
    } finally {
      if (billingAccounts && billingAccounts.length) {
        billingAccounts = BillingMethods.filterOutInactiveAccounts(billingAccounts);

        billingAccounts.forEach((account: Billing) => {
          if (account.isPaperless !== newPaperlessStatus && isValidForPaperlessBilling(account)) {
            const billingPolicyNumber: string = BillingMethods.getPolicyNumberFromBillingAccount(account);

            if (billingPolicyNumber) {
              const paperlessBilling: PaperlessBilling = new PaperlessBilling();
              paperlessBilling.accountNumber = account.number;
              paperlessBilling.policyNumbers = account.accountPolicies;
              paperlessBilling.isPaperless = newPaperlessStatus;
              paperlessBillingAccounts.push(paperlessBilling);
            }
          }
        });
      }
    }

    return paperlessBillingAccounts;
  }

  // NB Esign Paperless
  // build paperless update request for Billing given a specific list of Billing accounts
  // getBillingAccountByPolicyNumber(billingAccounts: Billing[], searchPolicyNumber: string) - returns Billing Accounts
  async buildBillingUpdateRequestForBillingAccounts(billingAccounts: Billing[], newPaperlessStatus: boolean): Promise<PaperlessBilling[]> {
    const paperlessBillingAccounts: PaperlessBilling[] = [];

    if (billingAccounts && billingAccounts.length) {
      billingAccounts = BillingMethods.filterOutInactiveAccounts(billingAccounts);

      billingAccounts.forEach((account: Billing) => {
        if (account.isPaperless !== newPaperlessStatus && isValidForPaperlessBilling(account)) {
          const billingPolicyNumber: string = BillingMethods.getPolicyNumberFromBillingAccount(account);

          if (billingPolicyNumber) {
            const paperlessBilling: PaperlessBilling = new PaperlessBilling();
            paperlessBilling.accountNumber = account.number;
            paperlessBilling.policyNumbers = [billingPolicyNumber];
            paperlessBilling.isPaperless = newPaperlessStatus;
            paperlessBillingAccounts.push(paperlessBilling);
          }
        }
      });
    }

    return paperlessBillingAccounts;
  }

  async buildPoliciesUpdateRequest(newPaperlessStatus: boolean, insuredFirstName: string): Promise<PaperlessPolicy[]> {
    const unknownPaperlessStatus: string = 'U';
    const paperlessPolicies: PaperlessPolicy[] = [];
    const newPaperlessStatusStr: string = newPaperlessStatus ? 'Y' : 'N';
    let policies: Policy[] = [];
    let primaryAgency: Agency = null;

    try {
      policies = await this.policyService.getPolicies();
      primaryAgency = await this.policyService.getPrimaryAgency();
    } catch (ex) {
      policies = null;
    } finally {
      if (policies && policies.length) {
        policies = PolicyMethods.getActivePolicies(policies);

        policies.forEach((policy: Policy) => {
          const currentEnrollmentStatus: string = policy.isPaperlessPolicy ? policy.isPaperlessPolicy.toUpperCase() : '';

          if (currentEnrollmentStatus && currentEnrollmentStatus !== newPaperlessStatusStr && currentEnrollmentStatus !== unknownPaperlessStatus) {
            const paperlessPolicy: PaperlessPolicy = new PaperlessPolicy();

            paperlessPolicy.policyNumber = policy.number;
            paperlessPolicy.insuredFirstName = insuredFirstName;
            paperlessPolicy.lineOfBusiness = policy.policyType ? policy.policyType.toLowerCase() : '';
            paperlessPolicy.companyName = policy.companyName;
            paperlessPolicy.isPaperless = newPaperlessStatus;

            if (primaryAgency) {
              paperlessPolicy.agencyEmailAddress = primaryAgency.emailAddress;
              paperlessPolicy.agencyName = primaryAgency.agencyName;
              paperlessPolicy.agencyPhoneNumber = primaryAgency.phoneNumber;
            }

            paperlessPolicies.push(paperlessPolicy);
          }
        });
      }
    }

    return paperlessPolicies;
  }

  // CA Paperless Re-enrollment - out of compliance paperless policies
  // build request for Policy update given an array of policy numbers - CA Paperless Re-enrollment to store T&C Doc
  async buildPoliciesUpdateRequestForReenrollPoliciesList(policyNumbers: string[], insuredFirstName: string): Promise<PaperlessPolicy[]> {
    if(!Array.isArray(policyNumbers) || !policyNumbers.length) {
      // do not process further
      return Promise.resolve(null);
    } else {
        const unknownPaperlessStatus: string = 'U';
        const paperlessPolicies: PaperlessPolicy[] = [];
        let policies: Policy[] = [];
        let primaryAgency: Agency = null;

        try {
          policyNumbers.forEach(policyNumber => {
            let policy: Policy = this.policyService.getPolicyByNumber(policyNumber);  
            policies.push(policy);
          });
          primaryAgency = await this.policyService.getPrimaryAgency();
        } catch (ex) {
          policies = null;
        } finally {
          if (policies && policies.length) {
            policies = PolicyMethods.getActivePolicies(policies);

            policies.forEach((policy: Policy) => {
              const currentEnrollmentStatus: string = policy.isPaperlessPolicy ? policy.isPaperlessPolicy.toUpperCase() : '';

              // make sure policy is truly Y for paperless before trying to store Paperless T&C document
              if (currentEnrollmentStatus === 'Y' && currentEnrollmentStatus !== unknownPaperlessStatus) {
                const paperlessPolicy: PaperlessPolicy = new PaperlessPolicy();

                paperlessPolicy.policyNumber = policy.number;
                paperlessPolicy.insuredFirstName = insuredFirstName;
                paperlessPolicy.lineOfBusiness = policy.policyType ? policy.policyType.toLowerCase() : '';
                paperlessPolicy.companyName = policy.companyName;
                paperlessPolicy.isPaperless = true;

                if (primaryAgency) {
                  paperlessPolicy.agencyEmailAddress = primaryAgency.emailAddress;
                  paperlessPolicy.agencyName = primaryAgency.agencyName;
                  paperlessPolicy.agencyPhoneNumber = primaryAgency.phoneNumber;
                }

                paperlessPolicies.push(paperlessPolicy);
              }
            });
          }
        }

        return paperlessPolicies;
    }
  }  

  // Used for NB Esign Paperless
  // build request for Policy update given an array of policy numbers - Use case NB Esign Paperless Sign up
  // only want to sign up just signed policies for Paperless not all on a customer's account
  async buildPoliciesUpdateRequestForPoliciesList(policyNumbers: string[], newPaperlessStatus: boolean, insuredFirstName: string): Promise<PaperlessPolicy[]> {
    if(!Array.isArray(policyNumbers) || !policyNumbers.length) { // test this, maybe revisit but it shouldn't get here from Esign complete
      // do not process further
      return Promise.resolve(null);
    } else {
        const unknownPaperlessStatus: string = 'U';
        const paperlessPolicies: PaperlessPolicy[] = [];
        const newPaperlessStatusStr: string = newPaperlessStatus ? 'Y' : 'N';
        let policies: Policy[] = [];
        let primaryAgency: Agency = null;

        try {
          policyNumbers.forEach(policyNumber => {
            let policy: Policy = this.policyService.getPolicyByNumber(policyNumber);  
            policies.push(policy);
          });
          primaryAgency = await this.policyService.getPrimaryAgency();
        } catch (ex) {
          policies = null;
        } finally {
          if (policies && policies.length) {
            policies = PolicyMethods.getActivePolicies(policies);

            policies.forEach((policy: Policy) => {
              const currentEnrollmentStatus: string = policy.isPaperlessPolicy ? policy.isPaperlessPolicy.toUpperCase() : '';

              if (currentEnrollmentStatus && currentEnrollmentStatus !== newPaperlessStatusStr && currentEnrollmentStatus !== unknownPaperlessStatus) {
                const paperlessPolicy: PaperlessPolicy = new PaperlessPolicy();

                paperlessPolicy.policyNumber = policy.number;
                paperlessPolicy.insuredFirstName = insuredFirstName;
                paperlessPolicy.lineOfBusiness = policy.policyType ? policy.policyType.toLowerCase() : '';
                paperlessPolicy.companyName = policy.companyName;
                paperlessPolicy.isPaperless = newPaperlessStatus;

                if (primaryAgency) {
                  paperlessPolicy.agencyEmailAddress = primaryAgency.emailAddress;
                  paperlessPolicy.agencyName = primaryAgency.agencyName;
                  paperlessPolicy.agencyPhoneNumber = primaryAgency.phoneNumber;
                }

                paperlessPolicies.push(paperlessPolicy);
              }
            });
          }
        }

        return paperlessPolicies;
    }
  }  

  async clearCachesAfterUpdate(paperlessDocuments: PaperlessDocuments): Promise<void> {
    if (this.shouldClearPolicyCacheAfterEmailUpdate(paperlessDocuments)) {
      try {
        const policies = await this.policyService.getPolicies(true);
      } catch (ex) {
        // continue regardless of error
      } finally {
        this.requestCache.cacheBust(Endpoints.api.getPolicies);
        this.requestCache.cacheBust(Endpoints.api.getPolicies + '?clearcache');
      }
    }

    if (this.shouldClearBillingCacheAfterEmailUpdate(paperlessDocuments)) {
      this.requestCache.cacheBust(Endpoints.api.getBilling);
      //this.requestCache.cacheBust(Endpoints.api.getPaperlessBilling);
    }
  }

  shouldClearPolicyCacheAfterEmailUpdate(paperlessDocuments: PaperlessDocuments): boolean {
    if (paperlessDocuments && paperlessDocuments.paperlessPolicies && paperlessDocuments.paperlessPolicies.length) {
      return paperlessDocuments.paperlessPolicies.some((paperlessPolicy: PaperlessPolicy) => paperlessPolicy.status && paperlessPolicy.status.isSuccessful);
    }

    return false;
  }

  shouldClearBillingCacheAfterEmailUpdate(paperlessDocuments: PaperlessDocuments): boolean {
    if (paperlessDocuments && paperlessDocuments.paperlessBilling && paperlessDocuments.paperlessBilling.length) {
      return paperlessDocuments.paperlessBilling.some((paperlessBilling: PaperlessBilling) => paperlessBilling.isSuccessful);
    }

    return false;
  }

  async buildPaperlessEmailRequest(): Promise<PaperlessDocuments> {
    let paperlessDocuments: PaperlessDocuments = new PaperlessDocuments();
    let userProfileEmailAddress: string;
    let insuredFirstName: string;

    this.paperlessUpdateAllEmailsObs = null;

    try {
      const authUser: AuthUser = this.accountService.getAuthUser();

      if (authUser) {
        userProfileEmailAddress = authUser.email;
        insuredFirstName = authUser.firstName;
      }
    } catch (ex) {
      userProfileEmailAddress = null;
    } finally {
      if (userProfileEmailAddress) {
        const paperlessBillingAccounts: PaperlessBilling[] = await this.getPaperlessBillingEmailToUpdate(userProfileEmailAddress);
        const paperlessPolicies: PaperlessPolicy[] = await this.getPaperlessPolicyEmailToUpdate(userProfileEmailAddress, insuredFirstName);

        if ((paperlessPolicies && paperlessPolicies.length) || (paperlessBillingAccounts && paperlessBillingAccounts.length)) {
          paperlessDocuments.paperlessBilling = paperlessBillingAccounts && paperlessBillingAccounts.length ? paperlessBillingAccounts : null;
          paperlessDocuments.paperlessPolicies = paperlessPolicies && paperlessPolicies.length ? paperlessPolicies : null;
          paperlessDocuments.emailAddress = userProfileEmailAddress;
        }
      } else {
        paperlessDocuments = null;
      }
    }

    return paperlessDocuments;
  }

  async getPaperlessBillingEmailToUpdate(userProfileEmail: string): Promise<PaperlessBilling[]> {
    let billingAccounts: Billing[] = [];
    const paperlessBillingAccounts: PaperlessBilling[] = [];

    try {
      billingAccounts = await this.billingService.getBilling();
    } catch (ex) {
      billingAccounts = null;
    } finally {
      if (billingAccounts && billingAccounts.length) {
        billingAccounts = this.billingService.filterActiveBillingAccounts(billingAccounts);

        billingAccounts.forEach((account: Billing) => {
          if (account.isPaperless && this.isCurrentEmailDifferentFromUserProfileEmail(userProfileEmail, account.paperlessEmail)) {
            const billingPolicyNumber: string = BillingMethods.getPolicyNumberFromBillingAccount(account);

            if (billingPolicyNumber) {
              const paperlessBilling: PaperlessBilling = new PaperlessBilling();

              paperlessBilling.accountNumber = account.number;
              paperlessBilling.policyNumbers = [billingPolicyNumber];
              paperlessBilling.emailCurrent = account.paperlessEmail;
              paperlessBilling.emailNew = userProfileEmail;

              paperlessBillingAccounts.push(paperlessBilling);
            }
          }
        });
      }
    }

    return paperlessBillingAccounts;
  }

  async getPaperlessPolicyEmailToUpdate(userProfileEmail: string, insuredFirstName: string): Promise<PaperlessPolicy[]> {
    const isEnrolledInPaperlessFlag: string = 'Y';
    const paperlessPolicies: PaperlessPolicy[] = [];
    let policies: Policy[] = [];
    let primaryAgency: Agency = null;

    try {
      policies = await this.policyService.getPolicies();
      primaryAgency = await this.policyService.getPrimaryAgency();
    } catch (ex) {
      policies = null;
    } finally {
      if (policies && policies.length) {
        policies = PolicyMethods.getActivePolicies(policies);

        policies.forEach((policy: Policy) => {
          if (policy.isPaperlessPolicy && policy.isPaperlessPolicy.toUpperCase() === isEnrolledInPaperlessFlag && this.isCurrentEmailDifferentFromUserProfileEmail(userProfileEmail, policy.paperlessEmail)) {

            const paperlessPolicy: PaperlessPolicy = new PaperlessPolicy();
            paperlessPolicy.policyNumber = policy.number;
            paperlessPolicy.insuredFirstName = insuredFirstName;
            paperlessPolicy.lineOfBusiness = policy.policyType ? policy.policyType.toLowerCase() : '';
            paperlessPolicy.companyName = policy.companyName;

            if (primaryAgency) {
              paperlessPolicy.agencyEmailAddress = primaryAgency.emailAddress;
              paperlessPolicy.agencyName = primaryAgency.agencyName;
              paperlessPolicy.agencyPhoneNumber = primaryAgency.phoneNumber;
            }

            paperlessPolicies.push(paperlessPolicy);
          }
        });
      }
    }

    return paperlessPolicies;
  }

  isCurrentEmailDifferentFromUserProfileEmail(userProfileEmail: string, currentEmail: string) {
    if (userProfileEmail && currentEmail) {
      userProfileEmail = userProfileEmail.toLowerCase();
      currentEmail = currentEmail.toLowerCase();

      if (userProfileEmail !== currentEmail) {
        return true;
      }
    }

    return false;
  }

  setHeapIdForPaperlessUpdates(updateResponse: PaperlessDocuments) {

    let heapIDAlert = '';
    switch (updateResponse.status.statusCode) {
      case PaperlessDocumentStatusCodes.incompleteInformationInRequest:
        heapIDAlert = `MMA-View-NotificationSystemError|PaperlessUpdate|IncompleteInformationInRequest`;
        break;
      case PaperlessDocumentStatusCodes.failureInBillingUpdates:
        heapIDAlert = `MMA-View-NotificationSystemError|PaperlessUpdate|FailureInBillingUpdate`;
        break;
      case PaperlessDocumentStatusCodes.failureInPolicyUpdates:
        heapIDAlert = `MMA-View-NotificationSystemError|PaperlessUpdate|FailureInPolicyUpdate`;
        break;
      case PaperlessDocumentStatusCodes.failureInBothUpdates:
        heapIDAlert = `MMA-View-NotificationSystemError|PaperlessUpdate|FailureInBothBillingAndPolicyUpdate`;
        break;
      case PaperlessDocumentStatusCodes.systemFailure:
        heapIDAlert = `MMA-View-NotificationSystemError|PaperlessUpdate|SystemFailure`;
        break;
      case PaperlessDocumentStatusCodes.exception:
        heapIDAlert = `MMA-View-NotifcationSystemError|PaperlessUpdate|Exception`;
        break;
      case PaperlessDocumentStatusCodes.aggregateException:
        heapIDAlert = `MMA-View-NotifcationSystemError|PaperlessUpdate|AggregateException`;
        break;
      default:
        break;
    }

    if (heapIDAlert) {
      this.analyticsService.trackAnalytics(heapIDAlert);
    }
  }

  getPaperlessTermsAndConditions(): Promise<string>  {
    let paperlessTermsContent = '';
    const endpoint = this.commonService.features?.paperless_terms_text_enabled ? Endpoints.cloudApi.getPaperlessTandCs : Endpoints.api.getPaperlessTermsAndConditions;
    this.paperlessTermsAndConditionsObs = this.http.get<PaperlessTermsResponse>(endpoint, { withCredentials: true });
    return new Promise((resolve, reject) => {
      this.paperlessTermsAndConditionsObs.subscribe({
        next: (response) => {
          if(response?.status?.code === 200){
            const result = response?.result;
            let content = "<h3 class='paperless-scroll-terms-title'>" + result.title + "</h3>";
            content += "<p>" + result.content + "</p>";

            let lastSection = false;
            for(let section = 0; section < result.sections.length; section++){
                content += "<h4 class='paperless-scroll-terms-title'>" + result.sections[section].sectionTitle + "</h4>";
                content += "<p>" + result.sections[section].content + "</p>";
                // check if on the last section - for styling purposes
                if(section === (result.sections.length - 1)) {
                  lastSection = true;
                }

                if (result.sections[section].items != null) {
                    if(lastSection) {
                      content += "<ul class='paperless-scroll-terms-ul paperless-scroll-terms-ul-last'>";
                    } else {
                      content += "<ul class='paperless-scroll-terms-ul'>";
                    }
                    for(let item = 0; item < result.sections[section].items.length; item++) {
                        if (result.sections[section].items[item].content) {
                            content += "<li>" + result.sections[section].items[item].content + "</li>";
                        } else if (result.sections[section].items[item].tokenizedContent) {
                            let linkText = result.sections[section].items[item].tokenizedContent;
                            let linkContent =  result.sections[section].items[item].linkContent
                            let link = "<a href=" + linkContent.linkUrl + " target=" + linkContent.linkTarget + ">" + linkContent.linkText + "</a>"
                            linkText = linkText.replace("{}", link);
                            content += "<li>" + linkText + "</li>";
                        }
                    }
                    content += "</ul>";
                  }
            } 
            paperlessTermsContent = content;
          } else {
            paperlessTermsContent = '';
          }
          resolve(paperlessTermsContent);
        },
        error: (error) => {
          paperlessTermsContent = '';
          reject({ error: error });
        }
      });
    });
  }

  private setTandCsSignedDateTime(isPaperless: boolean): string {
    let date = '';
    if (isPaperless) {
      const signedDate = new Date();
      const format = 'yyyy-MM-ddTHH:mm:ss';
      const locale = 'en-US';
      date = formatDate(signedDate, format, locale) + 'Z';
    }
    
    return date;
  }

}

export function isValidForPaperlessBilling(account: Billing) {
  if (account.isInactiveOrCanceledAccount || account.isPayByMortgagee || account.isPayrollDeduction) {
    return false;
  }
  return true;
}

class GetPaperlessBillingResponse {
  result: PaperlessBilling;
  status: {
    code: number;
  };
}

class GetPaperlessPolicyResponse {
  result: PaperlessPolicy;
  status: {
    code: number;
  };
}

class PaperlessDocumentsResponse {
  result: PaperlessDocuments;
  status: {
    code: number;
  };
}

class PaperlessTermsResponse {
  result: PaperlessTermsAndConditions;
  status: {
    code: number;
  }
}
