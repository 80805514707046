import Policy from '../models/policy';

export const LOB = {
    Auto: 1,
    Home: 2,
    Umbrella: 5,
    Boat: 7,
    DwellingFire: 8,
    RecVehicle: 9,
    Motorcycle: 10,
    Earthquake: 11,
    Renters: 401
};

export const LOBName = {
    auto: 'AUTO',
    dwellingFire: 'DWELLINGFIRE',
    earthquake: 'EARTHQUAKE',
    home: 'HOME',
    motorcycle: 'MOTORCYCLE',
    renters: 'RENTERS',
    umbrella: 'UMBRELLA',
    watercraft: 'WATERCRAFT'
};

export function convertLOBtoUIDisplay(lob: string) {
    lob = lob.toLowerCase();
    switch (lob) {
        case 'fire':
        case 'dwellingfire':
            return 'landlord';
        default:
            return lob;
    }
}

export function isGenericNickname(policy: Policy): boolean {
    if (policy.policyType) {
        const genericAutoNickname: string = 'AUTO';
        const genericHomeNickname: string = 'HOME';
        const policyNickname: string = policy.nickName ? policy.nickName.toUpperCase() : '';
        const translatedLOBCode: number = parseInt(translateLOBtoProductType(policy.policyType.toString()), 10);

        switch (translatedLOBCode) {
            case LOB.Auto:
            case LOB.Boat:
            case LOB.Home:
            case LOB.Motorcycle:
            case LOB.Renters:
                return (policyNickname === genericAutoNickname || policyNickname === genericHomeNickname);
            default:
                return true;
        }
    }

    return true;
}

export function isVehicularLOB(lineOfBusiness: number) {
    switch (lineOfBusiness) {
        case LOB.Auto:
        case LOB.Boat:
        case LOB.RecVehicle:
        case LOB.Motorcycle:
            return true;
        default:
            return false;
    }
}

export function isDwellingLOB(lineOfBusiness: number) {
    switch (lineOfBusiness) {
        case LOB.Home:
        case LOB.DwellingFire:
        case LOB.Earthquake:
        case LOB.Renters:
            return true;
        default:
            return false;
    }
}

const _lobProductType = {
    '1': '1',
    '11': '801',
    '8': '701',
    '2': '401',
    '10': '360',
    '9': '271',
    '401': '401',
    '5': '901',
    '7': '301'
};


export function translateLOBtoProductType(lob: string) {
    return _lobProductType[lob];
}

export function getLOBGroupType(lineOfBusiness: number) {
    switch (lineOfBusiness) {
        case LOB.Auto:
        case LOB.Boat:
        case LOB.RecVehicle:
        case LOB.Motorcycle:
            return 'AUTO';
        case LOB.Home:
        case LOB.DwellingFire:
        case LOB.Earthquake:
        case LOB.Renters:
            return 'HOME';
        case LOB.Umbrella:
            return 'UMBRELLA';
        default:
            return 'NOTFOUND';
    }
}

export function getLOBNameByNumber(lineOfBusiness: number) {
    let result = '';

    switch (lineOfBusiness) {
        case -1:
            result = 'All';
            break;
        case 0:
            result = 'Undefined';
            break;
        case 1:
            result = 'Auto';
            break;
        case 2:
            result = 'Home';
            break;
        case 3:
            result = 'ClientInfo';
            break;
        case 4:
            result = 'BOP';
            break;
        case 5:
            result = 'Umbrella';
            break;
        case 6:
            result = 'WorkerComp';
            break;
        case 7:
            result = 'Boat';
            break;
        case 8:
            result = 'DwellingFire';
            break;
        case 9:
            result = 'RecVehicle';
            break;
        case 10:
            result = 'Motorcycle';
            break;
        case 11:
            result = 'Earthquake';
            break;
        case 12:
            result = 'CMP';
            break;
        case 13:
            result = 'PGM';
            break;
        case 14:
            result = 'NA';
            break;
        case 15:
            result = 'AutoSOAP';
            break;
        case 16:
            result = 'Multiperil';
            break;
        case 17:
            result = 'Farm';
            break;
        default:
            break;
    }

    return result;

}

export const ICON_LOB_NAME = {
    auto: 'AUTO',
    dwellingfire: 'DWELLINGFIRE',
    fire: 'FIRE',
    earthquake: 'EARTHQUAKE',
    home: 'HOME',
    motorcycle: 'MOTORCYCLE',
    renters: 'RENTERS',
    umbrella: 'UMBRELLA',
    watercraft: 'WATERCRAFT',
    recvehicle: 'RECVEHICLE',
    rv: 'RV',
};
